const routes = {
    gameTypebyClientId:'gameType',
    getContestByGameAndCompanyId:'contests',
    userData:'auth/login',
    getInGameName: "inGameName/byUser",
    addInGameName: "inGameName/add",
    transactionId: "wallet/trxId",
    contestDetail:"contest/get/",
    myContestDetails:"mylist",
    joinContest: "JoinContest",
    joinViaCode: "contest/joinviainvitecode/",
    joinedContestUserByContestId: "joinedContestUserByContestId/",
    storeClients: "client/add",
}
export default routes