import routes from "./routes";
import url from "./url";
import axios from "axios";
const instance = axios.create({
  baseURL: url.api_url,
  headers: {
    "company-code":localStorage.getItem('compnay')!=null &&localStorage.getItem('compnay')!==undefined && localStorage.getItem('compnay')!=="undefined" ? JSON.parse(localStorage.getItem('compnay'))?.code:'NW',
    "lang":localStorage.getItem('onMobLanguage')
  }
});
instance.interceptors.request.use(function (config) {
  const companyCode = localStorage.getItem('compnay')!=null &&localStorage.getItem('compnay')!==undefined && localStorage.getItem('compnay')!=="undefined" ? JSON.parse(localStorage.getItem('compnay'))?.code:'NW';
  const language = localStorage.getItem('onMobLanguage');
  
  config.headers["company-code"] = companyCode;
  config.headers["lang"] = language;
  config.headers["Accept-Language"] = language;
  
  return config;
}, function (error) {
  return Promise.reject(error);
});
// const instanceOnMob = axios.create({
//   baseURL: url.onmob_url,
//   headers: {
//     "access_key_id": 'gamerji',
//     "access_key_secret": 'QO8IPsMUH5NvfHBVrucTlQ=='
//   }

// });
export const getClientUrls = () => {
  return instance.get(`${url.external_url}`)
    .then(res => {
      return res;
    })
};
export const storeClientIds = (data) => {
  return instance.post(`${url.base_url}${routes.storeClients}`, data)
    .then(res => {
      return res;
      // console.log(res)
    })
};
export const gameTypebyClientId = (data) => {
  return instance.post(`${url.api_url}${routes.gameTypebyClientId}`, data)
    .then(res => {
      return res;
      // console.log(res)
    })
};
export const getContestByGameAndCompanyId = (data) => {
  return instance.post(`${url.api_url}${routes.getContestByGameAndCompanyId}`, data)
    .then(res => {
      return res;
      // console.log(res)
    })
};
export const userData = (data) => {
  return instance.post(`${url.base_url}${routes.userData}`, data)
    .then(res => {
      return res;
      // console.log(res)
    })
};

export const getUserInGameName = (data) => {
  let  headers= {
    "company-code": JSON.parse(localStorage.getItem('compnay'))?.code }
    console.log("~~~addUserInGameName",data,headers)
  if(JSON.parse(localStorage.getItem('compnay'))?.code){
    console.log("~~~addUserInGameName```company code",data,headers)
  return instance.post(`${url.saasApiUrl}${routes.getInGameName}`, data,{headers})
    .then((res) => {
      return res;
    });
  }else{
    return false
  } 
};
export const addUserInGameName = (data) => {
 let  headers= {
    "company-code": JSON.parse(localStorage.getItem('compnay'))?.code }
    console.log("~~~addUserInGameName",data,headers)
  if(JSON.parse(localStorage.getItem('compnay'))?.code){
    console.log("~~~addUserInGameName```company code",data,headers)
    return instance.post(`${url.saasApiUrl}${routes.addInGameName}`, data,{headers})
    .then((res) => {
      return res;
    });
  }else{
    return false
  } 
 
};
export const contestDetail = (data, id,companyCode) => {
   let  headers= {
    "company-code":companyCode?.code }
    console.log("~^~contestDetail",data,headers)
  return instance.post(`${url.saasApiUrl}${routes.contestDetail}${id}`, data,{headers})
    .then((res) => {
      return res;
    });
};
export const myContestDetails = (data) => {
  return instance.post(`${url.saasApiUrl}${routes.addInGameName}`, data)
    .then((res) => {
      return res;
    });
};
export const joinContest = (data) => {
  console.log("*********************data",data)
  let  headers= {
    "company-code": JSON.parse(localStorage.getItem('compnay'))?.code }
  if(data?.inGameUserName==null){
    return false
  }else{
  return instance.post(`${url.api_url}${routes.joinContest}`, data,{headers})
    .then((res) => {
      return res;
    });
  }
 
};
export const getTransactionId = (lang, clientId) => {
  let URL ='';
  let headers = {}
  if(clientId == 'BTdemo' || clientId == 'caonmo') {
    URL = url.onmob_url
    headers = {
      "access_key_id": 'gamerji',
      "access_key_secret": 'QO8IPsMUH5NvfHBVrucTlQ=='
    }
  } else {
    URL = "https://rbtqa.onmobile.com/caBase/appserver/api/v1/"
    headers = {
      "access_key_id": 'gamerjitest',
      "access_key_secret": 'smwbQBr190sIMVy3uY+WGQ=='
    }
  }
  return axios.get(`${URL}${routes.transactionId}?language=${lang}&clientId=${clientId}`, {headers: headers})
    .then((res) => {
      return res;
    });
};
export const getWalletBalance = (userId, clientId) => {
  // let URL ='';
  // let headers = {}
  // if(clientId == 'BTdemo') {
  //   URL = url.onmob_url
  //   headers = {
  //     "access_key_id": 'gamerji',
  //     "access_key_secret": 'QO8IPsMUH5NvfHBVrucTlQ=='
  //   }
  // } else if(clientId == 'demo') {
  //   URL = "https://rbtqa.onmobile.com/caBase/appserver/api/v1/"
  //   headers = {
  //     "access_key_id": 'gamerjitest',
  //     "access_key_secret": 'smwbQBr190sIMVy3uY+WGQ=='
  //   }
  // }else if(clientId == 'sfesp'){
  //   URL = url.onmob_url
  //   headers = {
  //     "access_key_id": 'gamerji',
  //     "access_key_secret": 'QO8IPsMUH5NvfHBVrucTlQ=='
  //   }
  // }
  // return axios.get(`${URL}wallet/balance?clientId=${clientId}&userId=${userId}`, {headers: headers})
  //   .then((res) => {
  //     return res;
  //   });
  //console.log(`WALLET_URL::::   ${url.onmob_url}wallet/balance?clientId=${clientId}&userId=${userId}`)
  return instance.post(`${url.base_url}wallet/balance?clientId=${clientId}&userId=${userId}`)
  .then((res) => {
    return res;
  });

};
export const getMyContestList = (body) => {
  return instance.post(`${url.api_url}${routes.myContestDetails}`, body)
    .then((res) => {
      return res;
    });
};
//joinViaCode
export const joinViaCode = (body, id,companyCode) => {
  let  headers= {
    "company-code":companyCode?.code }
    console.log("~^~joinViaCode",body,headers)
  return instance.post(`${url.saasApiUrl + "v2/"}${routes.joinViaCode}${id}`, body,{headers})
    .then((res) => {
      return res;
    });
};
export const joinedContestUserByContestId = (body, id,companyCode) => {
  let  headers= {
    "company-code":companyCode?.code }
    console.log("~^~joinedContestUserByContestId",body,headers)
  return instance.post(`${url.saasApiUrl}${routes.joinedContestUserByContestId}${id}`, body,{headers})
    .then((res) => {
      return res;
    });
};

export const getSubscription = (body) => {
  return instance.get(`${url.base_url}payment/subscribe?svc=${body?.svc}&a=${body.a}&u=${body.u}&p=${body.p}&clientId=${body.clientId}`)
    .then((res) => {
      return res;
    });
};
export const getSubscription2 = (body) => {
  console.log("body",body)
  return instance.post(`${url.base_url}subscribe/get`,body)
    .then((res) => {
      return res;
    });
};

export const fetchHowToJoin = (body) => {
  return instance.post(`${url.api_url}how-to-play/getHowto`,body)
    .then((res) => {
      return res.data;
    });
};