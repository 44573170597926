import React, { useEffect, useState } from 'react'
import BasePopUp from './BasePopUp'
import { formatAMPM, getProfiledateFormat } from './Common'
import url from '../constants/url'
import Close from "../assets/images/close.svg";
import { getTransactionId, joinContest, getSubscription ,getWalletBalance} from '../constants/axios';
import { useTheme } from '../ThemeContext/ThemeContext';
import moment from 'moment/moment';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import clevertap from "clevertap-web-sdk";
import { useTranslation } from "react-i18next";

const ConfirmationPopUp = ({ allData, cancelConfirm, id }) => {
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = useState(false)
  const globalValues = useTheme()
  const [transactionId, setTransactionId] = useState('')
  const [isSubscription, setIsSubscription] = useState(false)
  const [subscriptionData, setSubscriptionData] = useState(null)
  const [coinBalance, setCoinBalance] = useState(null)
  clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
  clevertap.privacy.push({ useIP: true }); // Set the flag to true, if the user agrees to share their IP data
  clevertap.init(url.cleverTabDetails.cleverTapAccount, "in"); //
  clevertap.setLogLevel(3);

  const getBalance = async(onMonUserId,clientId) => {
    try {
      console.log(onMonUserId, clientId);
      const res = await getWalletBalance(onMonUserId, clientId)
      console.log(res)
      if (res.status === 200) {
        setCoinBalance(res?.data)
      }
      // setLoader(false)
    }
    catch (error) {
      console.log(error)
      let data = JSON.stringify({ "action": "back" });
      window.parent.postMessage(data, "*");

    }
  }

  const navigate = useNavigate()
  useEffect(() => {
    console.log("^^^^^^^^^^^^^globalValues",globalValues.clientId,globalValues.onMonUserId)
  //  getBalance(globalValues.onMonUserId,globalValues.clientId)
    //getTransactionID()
  }, [])

  useEffect(() => {
    console.log("^^^^^^^^^^^^^coinBalance",coinBalance)
  
  }, [coinBalance])

  // const getTransactionID = async () => {
  //   setShowLoader(true)
  //   const res = await getTransactionId("eng", globalValues?.clientId)
  //   if (res?.data) {
  //     setTransactionId(res?.data?.upsTrxId)
  //   }
  //   setShowLoader(false)
  // }
  // const oncheckSubscription = async () => {
  //   let body
  //   console.log(globalValues, '@@@@@@@@@buycoins');
  //   if(globalValues?.clientId && globalValues?.clientId==="sfesp" ){
  //     //console.log("url.sfespdemoSubs.",url.sfespdemoSubs)
  //     body = {
  //       svc : url.sfespdemoSubs.svcId,
  //       a:  url.sfespdemoSubs.appId,
  //       p:  url.sfespdemoSubs.packId,
  //       u: globalValues.onmopayU,
  //       clientId:globalValues.clientId
  //     }
  //   }
  //   else if(globalValues?.clientId && globalValues?.clientId==="BTdemo" ){
  //     console.log("url.sfespdemoSubs.",url.sfespdemoSubs)
  //     body = {
  //       svc : url.BTdemoSubs.svcId,
  //       a:  url.BTdemoSubs.appId,
  //       p:  url.BTdemoSubs.packId,
  //       u: globalValues.onmopayU,
  //       clientId:globalValues.clientId
  //     }
  //   } else if(globalValues?.clientId && globalValues?.clientId==="demo" ){
  //     console.log("url.sfespdemoSubs.",url.sfespdemoSubs)
  //     body = {
  //       svc : url.demoSubs.svcId,
  //       a:  url.demoSubs.appId,
  //       p:  url.demoSubs.packId,
  //       u: globalValues.onmopayU,
  //       clientId:globalValues.clientId
  //     }
  //   }
  //   console.log("^^^^subs body",body)
  //   const res = await getSubscription(body);
  //   console.log("^^^^response",res?.data);
  //   console.log("^^^^response",res?.data?.param.status);
  //   if(res.data.success && res?.data?.param && res?.data?.param?.status && res?.data?.param.status==='ACTIVE'){
  //     console.log("Subscription is Active")
  //     setIsSubscription(true);
  //     setSubscriptionData(res.data.param)
  //     return true
  //   }else {
  //     console.log("Subscription is Not Active|Staus:",res?.data?.param?.status)
  //     setIsSubscription(false);
  //     setSubscriptionData(null)
  //     return false
  //   }
  // }


  const onJoinContestHandler = async () => {
    console.log("cs",globalValues?.isSubscription,globalValues.subscriptionData)
    //console.log("allData?.gameType---------", allData,'globalValues?.balance?.coinBalance',globalValues?.balance?.coinBalance);
    
    //need to remove condition GO-91 
    // if(parseInt(allData?.entryFee) > coinBalance?.coinBalance) {
    //       let json_object = {"action": "buycoins","contestAmount":allData?.entryFee}
    //       console.log("buycoins",json_object)
    //       let data = JSON.stringify(json_object);
    //       window.parent.postMessage(data, "*");
        
    // } else {
      if (allData?.gameType.players > 1) {
        // let id = allData?.gameType.toLowerCase() === 'squad' ? 2 : 1;
        navigate(`/team-registration/${allData?._id}/${allData?.gameType?.players}?clientId=${globalValues?.clientId}&gameCode=${globalValues?.gameCode}&authCode=${globalValues.authCode}`)
        //window.location.href = `/team-registration/${allData?._id}/${allData?.gameType?.players}?clientId=${globalValues?.clientId}&gameCode=${globalValues?.gameCode}&authCode=${globalValues.authCode}`
      }
      else {
        const date = new Date()
        const timeStamp = Date.now()
       // console.log(date)
        const body = {
          "inGameUserName": globalValues?.inNameGame,
          "user": globalValues?.userId,
          "contest": allData?._id,
          "joinedDate": moment(new Date()),
          "JoinedTimestamp": timeStamp,
          "clientId": globalValues?.clientId,
          "isSubscribed":globalValues?.isSubscribed||false
         // "upsTrxId": transactionId // change
        }
        try {
          const res = await joinContest(body)
         // console.log('^^^^^^^^^^^^^^^^^',res,"*******",allData);
          if (res?.data?.success){
            let  json_object = {"action": "event" ,"eventName":"Gamerji_Amount_Debit", "event_properties":{
              "Amount":allData?.entryFee,
              "currencyType":allData?.currency[0]?.inCurrency?.name?"coin":"coin",
              "GameName":allData?.game,
              "type": "Contest",
              "typeId": allData?._id,
              "status": "Pass",
              "Date": new Date(),
              "userProperty": {
              "wallet_Balance": coinBalance?.coinBalance,
              "ClientId":globalValues?.clientId,
              "UserId":globalValues?.userId
              },
            }}
           let data = JSON.stringify(json_object);
           console.log(allData,'allData------Gamerji_Amount_Debit------',json_object);
           window.parent.postMessage(data, "*");
           let  json_object2 = {"action": "event" ,"eventName":"Gamerji_GameRegistered", "event_properties":{
            "GameName":allData?.game,
            "MatchDate": allData?.dateTime,
            "identity": globalValues?.userId,
            "Date": new Date(),
            "ClientId":globalValues?.clientId
          
          }}
         console.log("json data2-------------",json_object2)
         let data2 = JSON.stringify(json_object2);
          window.parent.postMessage(data2, "*");
            navigate(`/contest-details/${allData?._id}?clientId=${globalValues?.clientId}&gameCode=${globalValues?.gameCode}&authCode=${globalValues.authCode}`)
            //window.location.href = `/contest-details/${allData?._id}?clientId=${globalValues?.clientId}&gameCode=${globalValues?.gameCode}&authCode=${globalValues.authCode}`
          }
      } catch (error) {
           console.log("___2____ERR",error.response)
          if(error?.response.data?.data[0]?.msg)
            toast.error(error.response.data.data[0].msg)
            let data = JSON.stringify({ "action": "back" });
            console.log("back",data)
            window.parent.postMessage(data, "*");
        }
      // }
    }
  }
  return (
    <BasePopUp>
      <h2>{allData?.title?.toLowerCase()}</h2>
      <div
        className="closeButton"
        onClick={() => cancelConfirm()}
      >
        {/* <img src={Close} alt="" /> */}
        <div className='close'>X</div>
            
      </div>
      {
        isSubscription ?
        <div dangerouslySetInnerHTML={{__html: subscriptionData}}></div>
        : 
        <>
          <div className='confirm-head'>
            <span className='choose-text'>{allData?.game} {" "} {allData?.gameType?.name.toLowerCase()}</span>
            <span>{getProfiledateFormat(allData?.dateTime)}<span style={{ marginRight: '20px' }}>{"  "}</span> {formatAMPM(new Date(allData?.dateTime))}{ }</span>
          </div>
          <div className='confirmation-box'>
            <div className='third'><span>{t("confirmation_popup.coin_balance")}</span><span>
              <img src={url.imageUrl + allData?.currency[0]?.inCurrency?.img?.default } alt="" className="coin_img" style={{width: '25px'}}/>{(coinBalance?.coinBalance)?coinBalance?.coinBalance:0}</span>
            </div>
            <div className='first'><span className='choose-text'>{t("confirmation_popup.entry_fee")}</span><span>
              {allData?.entryFee != 0 &&
                <img
              src={
                url.imageUrl +
                allData?.currency[0]?.inCurrency?.img?.default
              }
              alt=""
              className="coin_img"
                />}
              {allData?.entryFee == 0 ? "free" : allData?.entryFee}</span>
            </div>
            {/* <div className='first'><span>Uasable Cash Bonus</span>
            <span><img
                            src={
                              url.imageUrl +
                              allData?.currency[0]?.inCurrency?.img?.default
                            }
                            alt=""
                            className="coin_img"
                          />0</span>
            </div> */}
            {/* <div className='first'><span>To pay</span>
              <span> <img
                            src={
                              url.imageUrl +
                              allData?.currency[0]?.inCurrency?.img?.default
                            }
                            alt=""
                            className="coin_img"
                          />{allData?.entryFee - 0}</span>
            </div> */}

            <div className='second'><button onClick={onJoinContestHandler}>{t("confirmation_popup.join_contest")}</button></div>


          </div>
        </>
      }
    </BasePopUp>
  )
}

export default ConfirmationPopUp