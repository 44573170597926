const s3_base_url = "https://gamerji-uploads.s3.amazonaws.com";

// const SAAS_API_URL = "https://onmo-api-by-eaas-staging.gamerji.cloud/" //staging
// const BASE_URL = "https://onmo-api-staging.gamerji.cloud/" //staging
// const ONMOB_URL = "https://demo.onmohub.com:8080/caBase/appserver/" //staging

// const SAAS_API_URL = "https://onmo-api-by-eaas-testing.gamerji.cloud/" //testing
const SAAS_API_URL = "https://onmo-api-by-eaas-testing.gamerji.cloud/" //testing
const BASE_URL = "https://onmo-api-testing.gamerji.cloud/" //testing
const ONMOB_URL = "https://ext-backendprod.challengesarena.com/appserver/" //testing


// const SAAS_API_URL = "http://localhost:9155/" //localhost
// const BASE_URL = "http://localhost:9090/" //localhost
// const ONMOB_URL = "https://ext-backendprod.challengesarena.com/appserver/" //localhost


// svc : '2602',
// a: '1432',
// p: '143201',
// u: globalValues.onmopayU
const demoSubs = {
    svcId:'2602',
    appId: '1432',
    packId: '143201'
}

const BTdemoSubs = {
    svcId:'80', // 2601
    appId: '3770',//1348
    packId: '19039'//13821
}

const demo = {
    svcId:'80', // 2601
    appId: '3770',//1348
    packId: '19039',//13821
}

const caonmo = {
    svcId:'80', // 2601
    appId: '3770',//1348
    packId: '19039',//13821
}

const sfespdemoSubs = {
    svcId:'107',
    appId: '3815',
    packId: '19190 ',
    // subscription_url:'http://epayh.onmohub.com/subscribe'
    // subscription_url:'http://epay.onmohub.com/subscribe', ///OLD SUBS URL v1  //change by kuldeep
     subscription_url:`https://safaricomke.onmobilepay.com/subscribe?svc=107`, ///NEw SUBS URL v2  //not using  //svcId change here also  //change by kuldeep
    // subscription_url:'http://epayapi.onmohub.com/subscribe'
    link1:'https://onmo-pwa-testing.gamerji.cloud/game-type?clientId=sfesp&gameCode=',
    link2:'https://esports.wineazy.com/sfesp/home/gamerjilanding?dl=',

    // link3:'http://epay.onmohub.com/subscribe?svc=107&clientId=sfesp&prurl=', //svcId change here also //v1   //change by kuldeep
    link3:'https://safaricomke.onmobilepay.com/subscribe?svc=107&clientId=sfesp&prurl=', //svcId change here also//v2  //change by kuldeep //Url for SUBCRIPTION REDIRECTION

}

const url = {
    api_url: SAAS_API_URL + "api/v2/onmo/",
    imageUrl: s3_base_url + "/",
    saasApiUrl: SAAS_API_URL + "api/",
    base_url: BASE_URL + "api/v1/",
    onmob_url: ONMOB_URL + "api/v1/",
    demoSubs: demoSubs,
    BTdemoSubs: BTdemoSubs,
    demo: demo,
    caonmo: caonmo,
    sfespdemoSubs: sfespdemoSubs,
    defaultPlayerLevelIcon: "https://gamerji-uploads.s3.amazonaws.com/632e92cecd46fcfb6cbcc444_1671556672654.png",
    cleverTabDetails: {
        cleverTap: "https://in1.api.clevertap.com/1/upload",
        cleverTapAccount: "867-4R8-KR6Z"
    },
    language_url : "https://gamerji-uploads.s3.ap-south-1.amazonaws.com/languages/om/en.json",
    external_url : "https://originals.wineazy.com/gamerji/configs/"
}

// api_url: SAAS_API_URL + "api/v2/onmo/",
// imageUrl: s3_base_url + "/",
// saasApiUrl: SAAS_API_URL + "api/",
// base_url: BASE_URL + "api/v1/",
// onmob_url: ONMOB_URL + "api/v1/",
// cleverTabDetails: {
//     cleverTap:"https://in1.api.clevertap.com/1/upload",
//     cleverTapAccount:"468-WW5-496Z"
// }


export default url